// The basics
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { frontloadConnect } from "react-frontload";
import LoadingBar from "react-redux-loading-bar";
import GDPR from "./components/GDPR";

// Action creators and helpers
// import { establishCurrentUser } from '../modules/auth';
import { getSystemInit } from "./containers/System/actions";

import { getMenus } from "./containers/Menus/actions";
import { getOptions } from "./containers/Options/actions";
import { isServer } from "../store";
import { setLanguage } from "./containers/Language/actions";

// import Header from './header';
import Routes from "./routes";
import Admin from "./components/Admin";

import "normalize.css/normalize.css";
import "animate.css/animate.min.css";
import "simple-line-icons/css/simple-line-icons.css";
import "bootstrap/dist/css/bootstrap.css";
import "slick-carousel/slick/slick.css";

import "./app.css";

const frontload = async props => {
  return Promise.all([
    props.setLanguage(props.match.params.lang),
    props.getSystemInit(props.match.params.lang)
  ]);
};

class App extends Component {
  constructor(props) {
    super(props);

    if (typeof window !== "undefined") {
      var browserUpdate = require("browser-update");

      browserUpdate({
        required: {
          e: -2,
          i: 11,
          f: -3,
          o: -3,
          s: 10.1,
          c: "64.0.3282.16817",
          samsung: 7.0,
          vivaldi: 1.2
        },
        insecure: true
      });
    }
  }
  componentWillMount() {
    // this.props.getMenus();
    // if (!isServer) {
    //   this.props.establishCurrentUser();
    // }
  }

  render() {
    const contentLength =
      (this.props.contentPage &&
        this.props.contentPage.acf &&
        this.props.contentPage.acf.content &&
        this.props.contentPage.acf.content.length) ||
      0;
    return (
      <div id="app">
        <LoadingBar style={{ zIndex: 999, backgroundColor: "#489ed8" }} />
        <GDPR content={this.props.gdpr} />

        <Routes />

        <Admin />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  contentPage: state.content.currentContent,
  gdpr:
    state.options && state.options.options.gdrp
      ? state.options.options.gdrp
      : {}
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ setLanguage, getSystemInit }, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    frontloadConnect(frontload, {
      onMount: true,
      onUpdate: false
    })(App)
  )
);
