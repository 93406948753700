import * as constants from './constants';
import config from '../../../config';

export function openAdmin(id) {
  return { type: constants.OPEN_ADMIN, payload: { id } };
}

export function toggleAdmin() {
  return { type: constants.TOGGLE_ADMIN };
}
