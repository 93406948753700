import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';

import auth from './auth';
import profile from './profile';
import content from '../app/containers/Content/Reducer';
import menus from '../app/containers/Menus/Reducer';
import options from '../app/containers/Options/Reducer';
import language from '../app/containers/Language/Reducer';
import admin from '../app/containers/Admin/Reducer';

export default combineReducers({
  auth,
  menus,
  content,
  profile,
  options,
  language,
  admin,
  loadingBar: loadingBarReducer,
});
